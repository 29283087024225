import parse, { ResourceAttributes } from '../parse'
import { parseProfile } from './profiles'

export function parseUser(attributes: ResourceAttributes) {
  return {
    id: attributes.id(),
    firstName: attributes.string('firstName'),
    lastName: attributes.string('lastName'),
    streamingAvailable: attributes.boolean('streamingAvailable'),
    paidStreamingAvailable: attributes.boolean('paidStreamingAvailable'),
    profiles: attributes.included('profiles', (profiles: any) => {
      return parse.many(profiles, parseProfile)
    }),
  }
}

export type UserObject = ReturnType<typeof parseUser>
