import crossFetch from 'cross-fetch'
import merge from 'lodash/merge'
import omit from 'lodash/omit'

import { APIResponse, APIRequestInit, APIError } from './types'

const DEFAULT_OPTIONS = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  mode: 'cors',
}

export * from './types'

export function rewrite(request: Request | string): Request | string {
  let url = typeof request === 'string' ? request : request.url
  if (url.indexOf('http') !== 0) {
    if (!process.env.NEXT_PUBLIC_API_URL) {
      throw new Error(
        `Environment variable \`NEXT_PUBLIC_API_URL\` is not set, cannot rewrite request url: ${url}`
      )
    }

    url = `${process.env.NEXT_PUBLIC_API_URL}${url}`
  }

  if (typeof request === 'string') {
    return url
  } else {
    return { ...request, url }
  }
}

function prepare(init: APIRequestInit = {}): RequestInit {
  const { body, ...properties } = init
  const options: RequestInit = merge({}, DEFAULT_OPTIONS, properties)

  // Authorize requests automatically
  if (init.token) {
    options.headers = Object.assign(options.headers || {}, {
      Authorization: `Bearer ${init.token}`,
    })
  }

  // Stringify body's that are objects and handle form uploads
  if (typeof FormData !== 'undefined' && body instanceof FormData) {
    if (typeof options.headers === 'object') {
      options.headers = omit(options.headers, [
        'Content-Type',
        'content-type',
      ]) as HeadersInit
    }

    options.body = body
  } else if (typeof body === 'object') {
    options.body = JSON.stringify(body)
  } else {
    options.body = body
  }

  return options
}

async function parse(response: Response): Promise<APIResponse> {
  try {
    if (response.status === 204)
      return new APIResponse({ response, body: undefined })
    const text = await response.text()
    const isJson =
      (response.headers.get('content-type') || '').indexOf(
        'application/json'
      ) !== -1
    const body = text && isJson ? JSON.parse(text) : text

    if (response.ok) {
      return new APIResponse({ response, body })
    } else {
      throw new APIError(
        `Invalid response status ${response.status}: ${response.url}`,
        {
          response: new APIResponse({ response, body }),
        }
      )
    }
  } catch (error) {
    if (error instanceof APIError) throw error
    throw new APIError(`Failed to parse response body of ${response.url}`, {
      response: new APIResponse({ response, body: undefined }),
      cause: error,
    })
  }
}

export default async function fetch(
  url: string | Request,
  init: APIRequestInit = {}
): Promise<APIResponse> {
  let response: Response | undefined
  const request = rewrite(url)
  const options = prepare(init)

  try {
    response = await crossFetch(request, options)

    // Handle redirects properly
    if (response.redirected) {
      return fetch(response.headers.get('Location') || request, init)
    }

    return parse(response)
  } catch (error) {
    if (response) {
      throw new APIError(
        `Failed to fetch ${
          typeof request === 'string' ? request : request.url
        }`,
        {
          response: new APIResponse({ response, body: undefined }),
          cause: error,
        }
      )
    }

    throw error
  }
}
