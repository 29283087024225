export function data(object: any, name: string) {
  if (typeof object === 'object' && typeof object.data === 'object') {
    return object.data[name]
  }

  return undefined
}

export function attribute(object: any, name: string) {
  const attributes = data(object, 'attributes')

  if (typeof attributes === 'object') {
    return attributes[name]
  }

  return undefined
}
