export interface APIRequestInit extends Omit<RequestInit, 'body'> {
  body?: RequestInit['body'] | any
  token?: string | false
}

interface APIResponseAttributes {
  response: Response
  body: any
}

export class APIResponse implements APIResponseAttributes {
  public response: Response
  public body: any

  constructor({ response, body }: APIResponseAttributes) {
    this.response = response
    this.body = body
  }

  get status() {
    return this.response.status
  }

  get statusText() {
    return this.response.statusText
  }
}

export class APIError extends Error {
  public cause?: Error
  public response?: APIResponse

  constructor(
    message: string,
    { response, cause }: { response?: APIResponse; cause?: Error }
  ) {
    super(message)

    this.response = response
    this.cause = cause

    Object.setPrototypeOf(this, new.target.prototype)

    this.name = 'APIError'
  }

  public get status() {
    return this.response ? this.response.response.status : 500
  }
}
