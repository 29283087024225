import fetch, { APIRequestInit } from '../fetch'
import parse, { Attributes } from '../parse'

import { ResourceAttributes } from '../parse'

export const AgeRating = {
  AL: 'AL',
  '6': '6',
  '9': '9',
  '12': '12',
}

function transformStringArray(data: any): string[] {
  if (!Array.isArray(data)) return []
  return data.filter((value: any) => typeof value === 'string') as string[]
}

function transformAchievementsSet(data: any) {
  if (!Array.isArray(data)) return []

  return data.map((achievement) => {
    return parse.object(achievement, (attributes: Attributes) => ({
      type: attributes.string('type'),
      collectedAt: attributes.date('collectedAt'),
    }))
  })
}

export function parseProfile(attributes: ResourceAttributes) {
  return {
    id: attributes.id(),
    favorites: attributes.many('favoriteShows'),
    name: attributes.string('name'),
    ageRating: attributes.enum<typeof AgeRating>('ageRating', AgeRating),
    achievements: attributes.custom('achievements', transformAchievementsSet),
    avatar: {
      type: attributes.optional.string('avatarType'),
      colors: attributes.optional.custom('avatarColors', transformStringArray),
    },
  }
}

export type ProfileObject = ReturnType<typeof parseProfile>

export async function fetchProfile(
  profileId: string,
  options: APIRequestInit = {}
) {
  const response = await fetch(`/api/v1/profiles/${profileId}`, options)
  return parse.one(response.body, parseProfile)
}

export async function updateProfile(
  profileId: string,
  avatar: { type: string; colors: [string, string, string] },
  options: APIRequestInit = {}
) {
  await fetch(`/api/v1/profiles/${profileId}`, {
    ...options,
    method: 'PATCH',
    body: {
      avatar_type: avatar.type,
      avatar_colors: avatar.colors,
    },
  })

  return true
}
