import { decode } from 'jsonwebtoken'
const TOKEN_TYPE = 'Bearer '

export function getTokenExpiresAt(token: string) {
  const decoded = decode(token)
  const expiresAt = new Date(0)

  if (
    decoded &&
    typeof decoded === 'object' &&
    (typeof decoded.exp === 'string' || typeof decoded.exp === 'number')
  ) {
    const exp =
      typeof decoded.exp === 'string' ? parseInt(decoded.exp, 10) : decoded.exp
    expiresAt.setUTCSeconds(exp)
  }

  return expiresAt.getTime()
}

export function getTokenExpiresIn(token: string) {
  const expiresAt = getTokenExpiresAt(token)
  const expiresIn = Math.abs(expiresAt - new Date().getTime())
  return Math.ceil(expiresIn / (1000 * 60 * 60 * 24))
}

export function getTokenFromResponse(response: Response) {
  const authorization = response.headers.get('Authorization')
  if (authorization && authorization.startsWith(TOKEN_TYPE)) {
    return authorization.substring(TOKEN_TYPE.length)
  }

  return undefined
}
