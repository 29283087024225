import { APIParseError } from './error'
import { data, attribute } from './attributes'
import { optional, compact, safely } from './utils'
import { relationships, included } from './relationships'

function transformId(value: any) {
  if (typeof value === 'string') return value
  if (typeof value === 'number') return String(value)

  throw new APIParseError(`Invalid id value: ${value}`)
}

function transformString(value: any) {
  if (typeof value === 'string') return value
  throw new APIParseError(`Invalid string value: ${value}`)
}

function transformNumber(value: any) {
  if (typeof value === 'number') return value
  throw new APIParseError(`Invalid number value: ${value}`)
}

function transformDate(value: any) {
  if (typeof value === 'string') {
    const date = new Date(value)
    const parsed = Date.parse(value)

    if (isNaN(parsed) || parsed <= 0) {
      throw new APIParseError(`Failed to parse date value of: ${value}`)
    }

    try {
      return date.toISOString()
    } catch (error) {
      throw new APIParseError(
        `Failed to serialize date with exception: ${error}`
      )
    }
  }

  throw new APIParseError(`Invalid date value: ${value}`)
}

function transformBoolean(value: any) {
  if (typeof value === 'boolean') return value
  throw new APIParseError(`Invalid boolean value: ${value}`)
}

function transformEnum<Options extends Record<string, string>>(
  value: any,
  options: Options
): keyof Options {
  const keys = Object.keys(options)
  const idx = keys.findIndex((key) => options[key] === value)

  if (idx !== -1) {
    return keys[idx] as keyof Options
  }

  throw new APIParseError(`Unavailable value: ${value}`)
}

function transformObject<TransformFn extends (value: any) => any>(
  value: any,
  transformFn?: TransformFn
): ReturnType<TransformFn> {
  if (typeof value === 'object') {
    if (transformFn) {
      return compact(transformFn(value))
    } else {
      return compact(value)
    }
  }

  throw new APIParseError(`Invalid object value: ${value}`)
}

function transformCustom<TransformFn extends (value: any) => any>(
  value: any,
  transformFn: TransformFn
): ReturnType<TransformFn> {
  return transformFn(value)
}

export function createTransformers(object: any = {}) {
  const transformers = {
    id: (name: any) => transformId(object[name]),
    string: (name: any) => transformString(object[name]),
    number: (name: any) => transformNumber(object[name]),
    boolean: (name: any) => transformBoolean(object[name]),
    date: (name: any) => transformDate(object[name]),
    enum: <Options extends Record<string, string>>(name: any, options: any) => {
      return transformEnum<Options>(object[name], options)
    },
    object: <TransformFn extends (name: any, transformFn?: TransformFn) => any>(
      name: any,
      transformFn?: TransformFn
    ): ReturnType<TransformFn> => {
      return transformObject(object[name], transformFn)
    },
    custom: <TransformFn extends (name: any, transformFn?: TransformFn) => any>(
      name: any,
      transformFn: TransformFn
    ): ReturnType<TransformFn> => {
      return transformCustom(object[name], transformFn)
    },
  }

  return {
    ...transformers,
    optional: {
      id: optional(transformers.id),
      string: optional(transformers.string),
      number: optional(transformers.number),
      date: optional(transformers.date),
      boolean: optional(transformers.boolean),
      enum: optional(transformers.enum),
      object: <TransformFn extends (object: any) => any>(
        name: string,
        transformFn?: TransformFn
      ): ReturnType<TransformFn> | undefined => {
        return safely(() => transformers.object(name, transformFn))
      },
      custom: <TransformFn extends (object: any) => any>(
        name: string,
        transformFn: TransformFn
      ): ReturnType<TransformFn> | undefined => {
        return safely(() => transformers.custom(name, transformFn))
      },
    },
  }
}

export function createResourceTransformers(object: any = {}) {
  const transformers = {
    id: (name?: string) =>
      transformId(name ? attribute(object, name) : data(object, 'id')),
    string: (name: string) => transformString(attribute(object, name)),
    number: (name: string) => transformNumber(attribute(object, name)),
    boolean: (name: string) => transformBoolean(attribute(object, name)),
    date: (name: string) => transformDate(attribute(object, name)),
    enum: <Options extends Record<string, string>>(
      name: string,
      options: any
    ) => {
      return transformEnum<Options>(attribute(object, name), options)
    },
    object: <TransformFn extends (object: any) => any>(
      name: string,
      transformFn?: TransformFn
    ): ReturnType<TransformFn> => {
      return transformObject(attribute(object, name), transformFn)
    },
    custom: <TransformFn extends (object: any) => any>(
      name: string,
      transformFn: TransformFn
    ): ReturnType<TransformFn> => {
      return transformCustom(attribute(object, name), transformFn)
    },
    one: (name: string) => {
      const relationship = relationships(object, name)

      if (typeof relationship === 'object') {
        if (Array.isArray(relationship)) {
          throw new APIParseError(
            `Found multiple objects for association: ${name}`
          )
        } else {
          return relationship.id
        }
      }

      throw new APIParseError(`Failed to locate association for: ${name}`)
    },
    many: (name: string) => {
      const relationship = relationships(object, name)

      if (typeof relationship === 'object') {
        if (Array.isArray(relationship)) {
          return relationship.map((rel) => rel.id)
        } else {
          throw new APIParseError(
            `Found single object for association: ${name}`
          )
        }
      }

      throw new APIParseError(`Failed to locate association for: ${name}`)
    },

    included: <TransformFn extends (object: any) => any>(
      name: string,
      transformFn: TransformFn
    ): ReturnType<TransformFn> => {
      const relationship = relationships(object, name)

      if (typeof relationship === 'object') {
        if (Array.isArray(relationship)) {
          const resources = relationship.map((id) => included(object, id))

          if (resources.some((resource) => typeof resource === 'undefined')) {
            throw new APIParseError(
              `Failed to locate included association for: ${name}`
            )
          }

          return transformFn({
            ...object,
            data: resources,
            included: [...(object.included || []), object.data],
          })
        } else {
          const resource = included(object, relationship)

          if (resource) {
            return transformFn({
              ...object,
              data: resource,
              included: [...(object.included || []), object.data],
            })
          } else {
            throw new APIParseError(
              `Failed to locate included association for: ${name}`
            )
          }
        }
      }

      throw new APIParseError(`Failed to locate relationship: ${name}`)
    },
  }

  return {
    ...transformers,
    optional: {
      id: optional(transformers.id),
      string: optional(transformers.string),
      number: optional(transformers.number),
      date: optional(transformers.date),
      boolean: optional(transformers.boolean),
      enum: optional(transformers.enum),
      included: <TransformFn extends (object: any) => any>(
        name: string,
        transformFn: TransformFn
      ): ReturnType<TransformFn> | undefined => {
        return safely(() => transformers.included(name, transformFn))
      },
      custom: <TransformFn extends (object: any) => any>(
        name: string,
        transformFn: TransformFn
      ): ReturnType<TransformFn> | undefined => {
        return safely(() => transformers.custom(name, transformFn))
      },
      object: <TransformFn extends (object: any) => any>(
        name: string,
        transformFn?: TransformFn
      ): ReturnType<TransformFn> | undefined => {
        return safely(() => transformers.object(name, transformFn))
      },
    },
  }
}
