import fetch, { APIRequestInit } from '../fetch'
import parse from '../parse'
import { parseShow } from './shows'

export async function fetchFavorites(
  profileId: string,
  options: APIRequestInit = {}
) {
  const response = await fetch(
    `/api/v1/profiles/${profileId}/favorites`,
    options
  )

  return parse.many(response.body, parseShow)
}

export async function createFavorite(
  profileId: string,
  showId: string,
  options: APIRequestInit = {}
) {
  await fetch(`/api/v1/profiles/${profileId}/favorites/${showId}`, {
    ...options,
    method: 'POST',
  })

  return true
}

export async function destroyFavorite(
  profileId: string,
  showId: string,
  options: APIRequestInit = {}
) {
  await fetch(`/api/v1/profiles/${profileId}/favorites/${showId}`, {
    ...options,
    method: 'DELETE',
  })

  return true
}
