import cookie from 'js-cookie'
import { getTokenExpiresIn } from './utils'

export const TOKEN_COOKIE = 'ck_session'
export const PROFILE_COOKIE = 'ck_profile'

// Token persistence
export const writeToken = (token?: string) => {
  if (token) {
    cookie.set(TOKEN_COOKIE, token, {
      expires: getTokenExpiresIn(token),
      sameSite: 'strict',
    })
  } else {
    cookie.remove(TOKEN_COOKIE)
  }
}

export const readToken = () => cookie.get(TOKEN_COOKIE)

// Profile persistence
export const writeProfile = (id?: string) => {
  if (id) {
    cookie.set(PROFILE_COOKIE, id, {
      sameSite: 'strict',
    })
  } else {
    cookie.remove(PROFILE_COOKIE)
  }
}

export const readProfile = () => cookie.get(PROFILE_COOKIE)
