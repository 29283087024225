function identifier(object: any): { type: string; id: string } | undefined {
  if (
    typeof object === 'object' &&
    typeof object.type === 'string' &&
    typeof object.id === 'string'
  ) {
    return { type: object.type, id: object.id }
  }

  return undefined
}

function relationship(object: any) {
  if (object && Array.isArray(object)) {
    return object
      .map((ident: any) => identifier(ident))
      .filter(Boolean) as NonNullable<ReturnType<typeof identifier>>[]
  } else {
    return identifier(object)
  }
}

export function relationships(object: any, name: string) {
  if (
    typeof object === 'object' &&
    typeof object.data === 'object' &&
    typeof object.data.relationships === 'object'
  ) {
    const relation = object.data.relationships[name]
    if (relation && relation.data) return relationship(relation.data)
  }

  return undefined
}

export function included(object: any, lookup: { id: string; type: string }) {
  if (object) {
    const included = object.included

    if (Array.isArray(included)) {
      const located = included.find((object: any) => {
        const includedObject = identifier(object)

        if (
          includedObject &&
          includedObject.type === lookup.type &&
          includedObject.id === lookup.id
        ) {
          return true
        }

        return false
      })

      if (located) return located
    }
  }

  return undefined
}
