import parse, { Attributes, safely } from '../parse'

export function parseImageVersion(attributes: Attributes) {
  return {
    url: attributes.string('url'),
    width: attributes.number('width'),
    height: attributes.number('height'),
  }
}

export function parseImage(attributes: Attributes) {
  return {
    name: attributes.string('name'),
    width: attributes.number('width'),
    height: attributes.number('height'),
    versions: attributes.object('versions', (versions: any) => {
      return {
        small: safely(() => parse.object(versions.small, parseImageVersion)),
        medium: safely(() => parse.object(versions.medium, parseImageVersion)),
        large: safely(() => parse.object(versions.large, parseImageVersion)),
      }
    }),
  }
}

export type ImageObject = ReturnType<typeof parseImage>
