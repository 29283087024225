import create from 'zustand'
import cookie from 'js-cookie'

import { getCookieDomain } from '@stores/analytics'
import { track } from '@components/AnalyticsProvider'

const SHOWN_WELCOME_COOKIE = 'welcome'
const SHOWN_WELCOME_TRUE = 'true'
const SHOWN_WELCOME_FALSE = 'false'
const HIDE_HELP_COOKIE = 'help'
const HIDE_HELP_TRUE = 'true'
const HIDE_HELP_FALSE = 'false'
const VIEW_COUNT_COOKIE = 'view_count'
const VIEW_LIMIT_DISABLED_COOKIE = 'view_count_disabled'
const VIEW_COUNT_LIMIT = Number(process.env.NEXT_PUBLIC_VIEW_COUNT_LIMIT || 3)

const viewCount = Number(cookie.get(VIEW_COUNT_COOKIE) || 0)
const viewLimitDisabled = !!cookie.get(VIEW_LIMIT_DISABLED_COOKIE) || false

export type UIStore = {
  hasUnread: boolean
  hideHelp: boolean
  viewCount: number
  viewLimitReached: boolean
  viewLimitDisabled: boolean
  hasNewTrophy: any
  hasOnlineResource: any
  modal?: string
  shownWelcome: boolean
  actions: {
    toggleModal: (modal?: string) => void
    showWelcome: () => void
    increaseViewCount: () => void
    setViewLimitDisabled: () => void
    setUnread: (hasUnread: boolean) => void
    setNewTrophy: (hasNewTrophy: any) => void
    setOnlineResource: (hasOnlineResource: any) => void
    setHideHelp: (hideHelp: boolean) => void
  }
}

const useUIStore = create<UIStore>((set, get) => {
  return {
    hasUnread: false,
    hasNewTrophy: false,
    hasOnlineResource: false,
    modal: undefined,
    shownWelcome: cookie.get(SHOWN_WELCOME_COOKIE) === SHOWN_WELCOME_TRUE,
    hideHelp: cookie.get(HIDE_HELP_COOKIE) === HIDE_HELP_TRUE,
    viewCount: viewCount,
    viewLimitReached: viewCount >= VIEW_COUNT_LIMIT,
    viewLimitDisabled: viewLimitDisabled,
    actions: {
      toggleModal(modal) {
        if (modal === 'feed') track('logboekOpened')

        set((s) => ({
          modal: s.modal === modal ? undefined : modal,
          hasNewTrophy: modal === 'newTrophy' ? s.hasNewTrophy : undefined,
          hasOnlineResource:
            modal === 'onlineResource' ? s.hasOnlineResource : undefined,
        }))
      },
      showWelcome() {
        if (!get().shownWelcome) {
          set({ shownWelcome: true, modal: 'welcome' })
        }
      },
      increaseViewCount() {
        set((state) => ({
          viewCount: state.viewCount + 1,
        }))
      },
      setViewLimitDisabled() {
        set({
          viewLimitDisabled: true,
        })
      },
      setUnread(hasUnread) {
        set({ hasUnread })
      },
      setNewTrophy(hasNewTrophy) {
        set((state) => ({
          hasNewTrophy,
          modal: !state.modal && hasNewTrophy ? 'newTrophy' : state.modal,
        }))
      },
      setOnlineResource(hasOnlineResource) {
        set((state) => ({
          hasOnlineResource,
          modal:
            !state.modal && hasOnlineResource ? 'onlineResource' : state.modal,
        }))
      },
      setHideHelp(hideHelp = true) {
        set({ hideHelp })
      },
    },
  }
})

// Whenever the token changes, write it to the localStorage
useUIStore.subscribe((state: UIStore) => {
  const isShown = state.shownWelcome

  cookie.set(
    SHOWN_WELCOME_COOKIE,
    isShown ? SHOWN_WELCOME_TRUE : SHOWN_WELCOME_FALSE,
    {
      expires: 365,
      domain: getCookieDomain(
        typeof location !== 'undefined' ? location.host : undefined
      ),
      sameSite: 'lax',
    }
  )

  const hideHelp = state.hideHelp

  cookie.set(HIDE_HELP_COOKIE, hideHelp ? HIDE_HELP_TRUE : HIDE_HELP_FALSE, {
    expires: 365,
    domain: getCookieDomain(
      typeof location !== 'undefined' ? location.host : undefined
    ),
    sameSite: 'lax',
  })

  const viewCount = state.viewCount

  cookie.set(VIEW_COUNT_COOKIE, viewCount.toString(), {
    expires: 365,
    domain: getCookieDomain(
      typeof location !== 'undefined' ? location.host : undefined
    ),
    sameSite: 'lax',
  })

  state.viewLimitReached = viewCount >= VIEW_COUNT_LIMIT

  const viewLimitDisabled = state.viewLimitDisabled

  if (viewLimitDisabled) {
    cookie.set(VIEW_LIMIT_DISABLED_COOKIE, 'true', {
      expires: 365,
      domain: getCookieDomain(
        typeof location !== 'undefined' ? location.host : undefined
      ),
      sameSite: 'lax',
    })
  }
})

export default useUIStore
