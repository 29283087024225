import fetch from '../fetch'
import parse, { ResourceAttributes } from '../parse'
import { parseImage } from './image'

export type ParentalGuidance =
  | 'AL'
  | '6'
  | '9'
  | '12'
  | 'ANGST'
  | 'GEWELD'
  | 'SEKS'
  | 'DISCRIMINATIE'
  | 'DRUGS'
  | 'TAALGEBRUIK'

function transformLanguage(data: any) {
  switch (data) {
    case 'nl_dubbed':
      return 'nl_dubbed' as const
    case 'nl':
      return 'nl' as const
    default:
      return undefined
  }
}

function transformPrice(data: any) {
  switch (data) {
    case 'paid':
      return 'paid' as const
    case 'free':
      return 'free' as const
    default:
      return undefined
  }
}

function transformStringArray(data: any): string[] {
  if (!Array.isArray(data)) return []
  return data.filter((value: any) => typeof value === 'string') as string[]
}

function transformGuidance(data: any): ParentalGuidance[] {
  return transformStringArray(data) as ParentalGuidance[]
}

function transformCredits(data: any): { [key: string]: string[] } {
  if (typeof data !== 'object') return {}

  const credits: { [key: string]: string[] } = {}
  for (const key in data) {
    const people = transformStringArray(data[key])
    if (people.length) credits[key] = people
  }

  return credits
}

function transformImage(object: any) {
  return parse.object(object, parseImage)
}

export function parseShowAttributes(attributes: ResourceAttributes) {
  return {
    id: attributes.id(),
    type: attributes.optional.string('type'),
    title: attributes.string('title'),
    description: attributes.string('description'),
    duration: attributes.optional.number('duration'),
    body: attributes.string('body'),
    category: attributes.optional.string('category'),
    genre: attributes.optional.string('genre'),
    genres: attributes.custom('genres', transformStringArray),
    image: attributes.optional.object('image', transformImage),
    language: attributes.custom('language', transformLanguage),
    parentalGuidance: attributes.custom('parentalGuidance', transformGuidance),
    labels: attributes.custom('labels', transformStringArray),
    tags: attributes.custom('tags', transformStringArray),
    trailerUrl: attributes.optional.string('trailerUrl'),
    trailerImage: attributes.optional.object('trailerImage', transformImage),
    credits: attributes.custom('credits', transformCredits),
    availabilityId: attributes.id('availabilityId'),
    availabilityType: attributes.string('availabilityType'),
    availabilityStartsAt: attributes.optional.date('availabilityStartsAt'),
    availabilityEndsAt: attributes.optional.date('availabilityEndsAt'),
    onlineResourceURL: attributes.optional.string('onlineResourceUrl'),
    favoritesCount: attributes.number('favoritesCount'),
    price: attributes.custom('price', transformPrice),
    festivalShow: attributes.boolean('festivalShow'),
  }
}

export function parseShow(attributes: ResourceAttributes) {
  return {
    ...parseShowAttributes(attributes),
    suggestions: attributes.optional.included(
      'suggestions',
      (suggestions: any) => {
        return parse.many(suggestions, parseShowAttributes)
      }
    ),
  }
}

export type ShowObject = ReturnType<typeof parseShow>

export async function fetchShow(id: string) {
  const response = await fetch(`/api/v1/shows/${id}`)
  return parse.one(response.body, parseShow)
}

export async function fetchShows() {
  const response = await fetch(`/api/v1/shows`)
  return parse.many(response.body, parseShow)
}
