import create from 'zustand'
import cookie from 'js-cookie'

export const getCookieDomain = (host = '') => {
  if (host.indexOf('cinekidplay.nl') !== -1) {
    return '.cinekidplay.nl'
  }

  return undefined
}

export const ACCEPT_COOKIE = 'ck_accept'
export const ACCEPT_COOKIE_VALUE_NO = 'no'
export const ACCEPT_COOKIE_VALUE_YES = 'v0'

export type AnalyticsStore = {
  enabled: boolean
  requested: boolean
  actions: {
    setEnabled: (isEnabled: boolean) => void
  }
}

const useAnalyticsStore = create<AnalyticsStore>((set) => {
  return {
    requested: !!cookie.get(ACCEPT_COOKIE),
    enabled: cookie.get(ACCEPT_COOKIE) === ACCEPT_COOKIE_VALUE_YES,
    actions: {
      setEnabled(isEnabled: boolean) {
        set({ enabled: isEnabled, requested: true })
      },
    },
  }
})

// Whenever the token changes, write it to the localStorage
useAnalyticsStore.subscribe<boolean | undefined>((state: AnalyticsStore) => {
  const isAccepted = state.enabled

  cookie.set(
    ACCEPT_COOKIE,
    isAccepted ? ACCEPT_COOKIE_VALUE_YES : ACCEPT_COOKIE_VALUE_NO,
    {
      expires: 365,
      domain: getCookieDomain(location?.host),
      sameSite: 'lax',
    }
  )
})

export default useAnalyticsStore
