import React, { useRef, useEffect, useLayoutEffect } from 'react'

import { useRouter } from 'next/router'
import useSessionStore, { SessionStore } from './'
const useIsomorphicEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect

const selectRestore = (store: SessionStore) => store.actions.restore
const selectSelectionRequired = (store: SessionStore) =>
  store.isLoading
    ? false
    : store.isAuthorized
    ? typeof store.profile === 'undefined'
    : false

export const SessionProvider: React.FC = ({ children }) => {
  const restore = useSessionStore(selectRestore)

  // Restore the session on the client side
  useIsomorphicEffect(() => {
    restore()
  }, [restore])

  return <React.Fragment>{children}</React.Fragment>
}

export const ProfileProvider: React.FC<{ enabled?: boolean }> = ({
  enabled,
  children,
}) => {
  const { replace } = useRouter()
  const isReplaced = useRef(false)
  const selectRequired = useSessionStore(selectSelectionRequired)
  const selectStart = enabled !== false && selectRequired

  useIsomorphicEffect(() => {
    if (selectStart) {
      if (!isReplaced.current) {
        isReplaced.current = true
        replace('/profielen')
      }
    }
  }, [selectStart])

  return <React.Fragment>{children}</React.Fragment>
}
