export class APIParseError extends Error {
  public isAPIParseError = true

  constructor(message: string) {
    super(message)

    Object.setPrototypeOf(this, new.target.prototype)
    this.name = 'APIParseError'
  }
}
