/*
 * This started more sensible as it ended up as
 * Outdated test set available at: https://codesandbox.io/s/lingering-tree-0u1c0
 *
 **/

import { createResourceTransformers, createTransformers } from './transform'
import { compact } from './utils'

export * from './error'
export * from './transform'
export * from './attributes'
export * from './relationships'
export * from './utils'

export type Attributes = ReturnType<typeof createTransformers>
export type ParseFn<Result extends any> = (attributes: Attributes) => Result

export function object<Result extends any>(
  object: any,
  transform: ParseFn<Result>
): Result {
  return compact(transform(createTransformers(object)))
}

export type ResourceAttributes = ReturnType<typeof createResourceTransformers>
export type ResourceParseFn<Result extends any> = (
  attributes: ResourceAttributes
) => Result

export interface ParseManyOptions {
  ignore: boolean
}

export function one<Result extends any>(
  object: any,
  transform: ResourceParseFn<Result>
): Result {
  return compact(transform(createResourceTransformers(object)))
}

export function many<Result extends any>(
  object: any,
  transform: (attributes: ResourceAttributes) => Result,
  options: ParseManyOptions = { ignore: true }
): Result[] {
  if (typeof object === 'object' && Array.isArray(object.data)) {
    return object.data
      .map((data: any) => {
        try {
          return compact(
            transform(createResourceTransformers({ ...object, data }))
          )
        } catch (error) {
          if (options.ignore && error.isAPIParseError) {
            console.warn('Failed to parse collection resource', error)
            return undefined
          }

          throw error
        }
      })
      .filter(Boolean) as Result[]
  }

  return []
}

export default { one, many, object }
