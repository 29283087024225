import create from 'zustand'
import * as Sentry from '@sentry/node'
import differenceBy from 'lodash/differenceBy'
import sortBy from 'lodash/sortBy'
import fetch, { APIError, APIResponse } from '../fetch'
import parse from '../parse'

import useUIStore from '@stores/ui'
import { parseUser, UserObject } from '../resources/users'
import {
  fetchProfile,
  updateProfile,
  ProfileObject,
} from '../resources/profiles'
import { createFavorite, destroyFavorite } from '../resources/favorites'
import { readToken, writeToken, readProfile, writeProfile } from './persist'
import { getTokenExpiresAt, getTokenFromResponse } from './utils'

export const STORAGE_KEY = '_ck_session'
export * from './Provider'

export type SessionStore = {
  isLoading: boolean
  isAuthorized: boolean
  profile?: string
  user?: UserObject
  error?: string
  token?: string
  actions: {
    restore: () => Promise<boolean>
    loginWithToken: (token: string) => Promise<boolean>
    login: (email?: string, password?: string) => Promise<boolean>
    logout: () => Promise<boolean>
    setProfile: (profileId?: string) => void
    getProfile: (profileId?: string) => Promise<boolean>
    setAvatar: (
      type: string,
      colors: [string, string, string]
    ) => Promise<boolean>
    setFavorited: (showId: string, isFavorite: boolean) => Promise<boolean>
  }
}

const INITIAL_STATE = {
  isAuthorized: false,
  isLoading: false,
  error: undefined,
  token: undefined,
  user: undefined,
  profile: undefined,
}

const validateProfileSelection = (user?: UserObject, profileId?: string) => {
  const profiles = user ? user.profiles : []

  // If we have a selected profile
  if (profileId) {
    const idx = profiles.findIndex((profile) => profile.id === profileId)
    if (idx !== -1) return profileId
  }

  // If we only have one profile
  if (profiles.length === 1) return profiles[0].id

  // No clue
  return undefined
}

const useSessionStore = create<SessionStore>(
  (set, getState): SessionStore => {
    const loginWithResponse = (response: APIResponse, profile?: string) => {
      const token = getTokenFromResponse(response.response)
      const tokenExpiresAt = token ? getTokenExpiresAt(token) : -1

      if (token && tokenExpiresAt > new Date().getTime()) {
        try {
          const user = parse.one(response.body, parseUser)

          set({
            ...INITIAL_STATE,
            token,
            user,
            profile: validateProfileSelection(user, profile),
            isAuthorized: true,
          })

          return true
        } catch (error) {
          set({
            ...INITIAL_STATE,
            error: 'Oeps, er ging wat mis (BAD_RESPONSE)',
          })

          return false
        }
      } else {
        if (token) {
          console.warn('Received expired token with response', response)
        } else {
          console.warn('No token in response', response)
        }

        set({
          ...INITIAL_STATE,
          error: 'Oeps, er ging wat mis (BAD_TOKEN)',
        })

        return false
      }
    }

    const loginWithCredentials = async (email: string, password: string) => {
      // Start with a loading state
      set((state) => ({ ...state, isLoading: true }))

      try {
        const response = await fetch('/api/v1/sessions', {
          method: 'POST',
          body: { user: { email, password } },
        })

        return loginWithResponse(response)
      } catch (error) {
        if (error instanceof APIError) {
          console.info(
            `Failed to authorize user got ${error.status} response`,
            error
          )

          const errors = error.response?.body?.errors
          if (Array.isArray(errors)) {
            set({
              ...INITIAL_STATE,
              error: errors
                .map((err) => err?.detail)
                .filter(Boolean)
                .join(','),
            })
          } else {
            set({
              ...INITIAL_STATE,
              error: 'Oeps, er ging wat mis (UNKNOWN_ERROR)',
            })
          }
        } else {
          console.error('Unknown error during authorization', error)
          set({
            ...INITIAL_STATE,
            error: 'Oeps, er ging wat mis (UNKNOWN_RESPONSE)',
          })
        }
      }

      return false
    }

    const loginWithToken = async (token?: string, profile?: string) => {
      const expiresAt = token ? getTokenExpiresAt(token) : undefined

      if (expiresAt && expiresAt > new Date().getTime()) {
        try {
          const response = await fetch('/api/v1/me', { token })
          return loginWithResponse(response, profile)
        } catch (error) {
          if (error instanceof APIError) {
            console.info(
              `Failed to authorize user got ${error.status} response`,
              error
            )
          } else {
            console.error('Unknown error during authorization', error)
          }
        }
      }

      // Restoring the session failed
      set(INITIAL_STATE)

      // Expose the failed state to the restore action
      return false
    }

    const replaceProfile = (profile: ProfileObject) => {
      // Mutate the state so it's saved
      set((state) => {
        const user = state.user
        const profiles = user?.profiles || []
        const profileIdx = profiles.findIndex((p) => p.id === profile.id)

        if (!user || profileIdx === -1) return state

        return {
          ...state,
          user: {
            ...user,
            profiles: [
              ...profiles.slice(0, profileIdx),
              profile,
              ...profiles.slice(profileIdx + 1),
            ],
          },
        }
      })
    }

    const currentToken = readToken()
    const currentProfile = readProfile()

    return {
      ...INITIAL_STATE,
      token: currentToken,
      profile: currentProfile,
      isLoading: true,
      isAuthorized: !!currentToken,
      actions: {
        restore: () => {
          return loginWithToken(readToken(), readProfile())
        },
        loginWithToken: (token: string, profileId?: string) => {
          return loginWithToken(token, profileId)
        },
        logout: async () => {
          try {
            await fetch(`/api/v1/sessions`, {
              method: 'DELETE',
              token: getState().token,
            })
          } catch (error) {
            if (error instanceof APIError) {
              console.info(
                `Failed to logout user, got ${error.status} response`,
                error
              )
            }
          }

          set({ ...INITIAL_STATE })
          return Promise.resolve(true)
        },
        login: (email?: string, password?: string) => {
          if (email && password) {
            return loginWithCredentials(email, password)
          } else {
            return Promise.resolve(false)
          }
        },
        getProfile: async (profileId?: string) => {
          const state = getState()
          if (!state.isAuthorized || !state.token || !profileId) return false

          try {
            const profile = await fetchProfile(profileId, {
              token: getState().token,
            })

            const oldProfile = state.user
              ? state.user.profiles.find((profile) => profile.id === profileId)
              : undefined

            if (oldProfile) {
              const newAchievements = differenceBy(
                profile.achievements,
                oldProfile.achievements,
                'type'
              )
              useUIStore
                .getState()
                .actions.setNewTrophy(
                  sortBy(
                    newAchievements,
                    (a) => new Date(a.collectedAt)
                  ).reverse()[0]
                )
            }

            replaceProfile(profile)

            return true
          } catch (error) {
            return false
          }
        },
        setProfile: (profileId?: string) => {
          return set((state) => ({
            ...state,
            profile: validateProfileSelection(state.user, profileId),
          }))
        },
        setAvatar: async (type: string, colors: [string, string, string]) => {
          const state = getState()
          const profile = (state?.user?.profiles || []).find(
            (profile) => profile.id === state.profile
          )

          if (!profile) return false

          try {
            await updateProfile(
              profile.id,
              { type, colors },
              { token: state.token }
            )

            // Mutate the state so it's saved
            replaceProfile({ ...profile, avatar: { type, colors } })

            return true
          } catch (error) {
            return false
          }
        },
        setFavorited: async (showId: string, isFavorite: boolean) => {
          const state = getState()
          const profile = (state?.user?.profiles || []).find(
            (profile) => profile.id === state.profile
          )

          if (!profile) return false

          try {
            if (isFavorite) {
              await createFavorite(profile.id, showId, { token: state.token })

              // Mutate the state so it's included
              set((state) => {
                const user = state.user
                const profiles = user?.profiles || []
                const profileIdx = profiles.findIndex(
                  (p) => p.id === profile.id
                )

                if (!user || profileIdx === -1) return state

                return {
                  ...state,
                  user: {
                    ...user,
                    profiles: [
                      ...profiles.slice(0, profileIdx),
                      { ...profile, favorites: [showId, ...profile.favorites] },
                      ...profiles.slice(profileIdx + 1),
                    ],
                  },
                }
              })

              return true
            } else {
              await destroyFavorite(profile.id, showId, { token: state.token })

              // Mutate the state so it's removed
              set((state) => {
                const user = state.user
                const profiles = user?.profiles || []
                const profileIdx = profiles.findIndex(
                  (p) => p.id === profile.id
                )

                if (!user || profileIdx === -1) return state

                return {
                  ...state,
                  user: {
                    ...user,
                    profiles: [
                      ...profiles.slice(0, profileIdx),
                      {
                        ...profile,
                        favorites: profile.favorites.filter(
                          (id) => id !== showId
                        ),
                      },
                      ...profiles.slice(profileIdx + 1),
                    ],
                  },
                }
              })

              return true
            }
          } catch (error) {
            console.warn('Failed to change favorite status', error)
            return false
          }
        },
      },
    }
  }
)

useSessionStore.subscribe((session) => {
  const trace = (window as any)._CK_DEBUG_SESSION
  if (typeof trace === 'function') trace(session)
})

useSessionStore.subscribe<{ id?: string; profile?: string }>(
  (context) => Sentry.setContext('user', context || {}),
  (state) => {
    return { id: state.user?.id, profile: state.profile }
  }
)

// Whenever the token changes, write it to the localStorage
useSessionStore.subscribe<string | undefined>(
  (token) => (token ? writeToken(token) : writeToken(undefined)),
  (state) => state.token
)

// Whenever the selected profile changes, write it to the localStorage
useSessionStore.subscribe<string | undefined>(
  (profile) => (profile ? writeProfile(profile) : writeProfile(undefined)),
  (state) => state.profile
)

export default useSessionStore
